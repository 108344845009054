import React from 'react';
import defaultErrorImage from '../assets/svg/illustration-15.svg';
import { useTranslation, Trans } from "react-i18next";

const ErrorPage = ({ errorImageUrl }) => {

  const getContent = () => {
    const content =
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
        </nav>
        <div className="container">
          <div className="row row-grid align-items-center">
            <div className="col-12 col-md-5 col-lg-6 order-md-2 text-center">
              <figure className="w-100">
                <img alt="Welcome" src={errorImageUrl || defaultErrorImage} className="img-fluid mw-md-120" />
              </figure>
            </div>
            <div className="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">
              <h1 className="display-4 text-center text-md-left mb-3">
              <Trans i18nKey="errorMessage"></Trans>
              </h1>
              <p className="lead text-center text-md-left text-muted">
              <Trans i18nKey="contactResponsableMessage"></Trans>
              </p>
            </div>
          </div>
        </div>
      </div>;

    return content;
  };

  return (
    getContent()
  );
};

export default ErrorPage;
