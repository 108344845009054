import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import AsyncComponent from './components/AsyncComponent';
import App from './components/App';
import Loading from './components/common/Loading';

const InstructionPage = () => import('./components/InstructionPage');
const WelcomePage = () => import('./components/WelcomePage');
const PersonalPage = () => import('./components/PersonalPage');
const PersonalAddressPage = () => import('./components/PersonalAddressPage');
const PersonalDocPage = () => import('./components/PersonalDocPage');
const PersonalDocPage2 = () => import('./components/PersonalDocPage2');
const PersonalDocPage3 = () => import('./components/ReservistCertificate');
const PersonalReview = () => import('./components/PersonalReview');
const SuccessPage = () => import('./components/SuccessPage');
const SavePage = () => import('./components/SavePage');
const DocImgPage = () => import('./components/DocImgPage');
const Dependents = () => import('./components/PersonDependents');
const Download = () => import('./components/Download');
const MaritalStatus = () => import('./components/MaritalStatusPage');
const PersonPis = () => import('./components/PersonPis');
const PersonClassOrgan = () => import('./components/PersonClassOrgan');
const PersonEducation = () => import('./components/PersonEducation');
const PersonCertificate = () => import('./components/PersonCertificate');
const PersonDeficiency = () => import('./components/PersonDeficiency');
const PersonHealthPlanPage = () => import('./components/PersonHealthPlanPage');
const PersonTransportPage = () => import('./components/PersonTransportPage');
const PersonFoodPage = () => import('./components/PersonFoodPage');
const PersonLanguagePage = () => import('./components/PersonLanguage');
const PersonBankPage = () => import('./components/PersonBankPage');

const PersonMetaPage = () => import('./components/metadados/MetadadosPage');

const AppRoutes = () => (
  <HashRouter>
    <div>
      <App>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/instruction' element={<AsyncComponent importComponent={InstructionPage} />} />
            <Route path='/welcome' element={<AsyncComponent importComponent={WelcomePage} />} />
            <Route path='/personal' element={<AsyncComponent importComponent={PersonalPage} />} />
            <Route path='/personaladdress' element={<AsyncComponent importComponent={PersonalAddressPage} />} />
            <Route path='/personaldoc' element={<AsyncComponent importComponent={PersonalDocPage} />} />
            <Route path='/personaldoc2' element={<AsyncComponent importComponent={PersonalDocPage2} />} />
            <Route path='/personaldoc3' element={<AsyncComponent importComponent={PersonalDocPage3} />} />
            <Route path='/personalreview' element={<AsyncComponent importComponent={PersonalReview} />} />
            <Route path='/savePage' element={<AsyncComponent importComponent={SavePage} />} />
            <Route path='/success' element={<AsyncComponent importComponent={SuccessPage} />} />
            <Route path='/docimg' element={<AsyncComponent importComponent={DocImgPage} />} />
            <Route path='/dependents' element={<AsyncComponent importComponent={Dependents} />} />
            <Route path='/download' element={<AsyncComponent importComponent={Download} />} />
            <Route path='/maritalStatus' element={<AsyncComponent importComponent={MaritalStatus} />} />
            <Route path='/personPis' element={<AsyncComponent importComponent={PersonPis} />} />
            <Route path='/personClassOrgan' element={<AsyncComponent importComponent={PersonClassOrgan} />} />
            <Route path='/personEducation' element={<AsyncComponent importComponent={PersonEducation} />} />
            <Route path='/personDeficiency' element={<AsyncComponent importComponent={PersonDeficiency} />} />
            <Route path='/personCertificate' element={<AsyncComponent importComponent={PersonCertificate} />} />
            <Route path='/PersonHealthPlan' element={<AsyncComponent importComponent={PersonHealthPlanPage} />} />
            <Route path='/PersonOdonto' element={<AsyncComponent importComponent={PersonHealthPlanPage} />} />
            <Route path='/PersonTransport' element={<AsyncComponent importComponent={PersonTransportPage} />} />
            <Route path='/PersonFood' element={<AsyncComponent importComponent={PersonFoodPage} />} />
            <Route path='/languages' element={<AsyncComponent importComponent={PersonLanguagePage} />} />
            <Route path='/BankAccount' element={<AsyncComponent importComponent={PersonBankPage} />} />

            <Route exact path='/personMeta/:name' element={<AsyncComponent importComponent={PersonMetaPage} />} />

            <Route path='*' element={<Navigate to='/welcome' />} />
          </Routes>
        </Suspense>
      </App>
    </div>
  </HashRouter>
);

export default AppRoutes;
