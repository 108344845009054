import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import services from '../api/services/personServices';
import stepServices from '../api/services/stepServices';
import clientServices from '../api/services/clientParameterService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validationUtils from './common/validationUtils';
import { usePerson } from '../hooks/usePerson';
import { useActiveMenu } from '../hooks/useActiveMenu';
import { useTranslation } from "react-i18next";
import "./i18n";
import toastr from 'toastr';

const App = props => {

  const [parameter, setParameter] = useState({});
  const [menu, setMenu] = useState([]);
  const [checkedLi, setCheckedLi] = useState('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23152c5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>');
  const [li,] = useState('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23718096" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>');

  const [menuConfirm, setMenuConfirm] = useState([]);
  const division = useSelector(state => state.division);
  const navigate = useNavigate();
  const { person, setPerson } = usePerson();
  const { activeMenu, setActiveMenu } = useActiveMenu();
  const [copyPerson, setCopyPerson] = useState({});

  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


  const initializeParameter = () => {
    services.getParameters(division.divisionId).then(res => {
      setParameter(res.data);
      let ck = checkedLi.replace('%23152c5b', res.data.backgroundColor.replace('#', '%23'))
      setCheckedLi(ck);
      changeLanguage(res.data.codeLanguage);
    });
  };

  const stepCompanyList = () => {
    stepServices.stepCompanyList(division.divisionId).then(res => { setMenu(res.data); });
  };

  const navigationMenu = () => {
    clientServices.get().then(res => {
      const newPerson = { ...person, hasNavigationMenu: res.hasNavigationMenu };
      setPerson(newPerson);
    });
  };

  const stepSavedList = () => {
    stepServices.stepSavedList().then(res => { setMenuConfirm(res.data); });
  };

  const next = (data) => {

    const valid = validationUtils.validaCopy(person, copyPerson);

    if (data.id == 0) {
      data.order = 0;
    }

    if ((menu.length - menuConfirm.length) > 1 && data.id == 0) {
      const message = [];
      
      menu.forEach(element => {
        if(!menuConfirm.find(i => i == element.idHreStep) && element.stepName != 'Resumo'){
          message.push({ message: element.stepName });
        }
      });

      let m = message.map(res => { return '<li>' + res.message + '</li>'; });
      toastr.warning(['Antes de acessar o resumo, utilize o menu de navegação à sua esquerda para conferir o preenchimento do(s) campo(s) obrigatório(s) da(s) seguinte(s) tela(s):',...m], { enableHtml: true });

      return;
    }
    if (valid) {
      if (window.confirm(i18n.t("leaveWithoutSaving"))) {
        const newPerson = structuredClone(person);
        setCopyPerson(newPerson);

        navigate(data.url, { state: data });
        setActiveMenu(data.id);
      }
    } else {
      navigate(data.url, { state: data });
      setActiveMenu(data.id);
    }
  };

  useEffect(() => {
    navigationMenu();
    initializeParameter();
    stepCompanyList();
    stepSavedList();
    setCopyPerson(person);

  }, []);

  useEffect(() => {
    stepSavedList();
  }, [activeMenu]);

  useEffect(() => {

    if (person.isLoad) {
      const newPerson = structuredClone({ ...person, isLoad: false });
      setPerson(newPerson);
      setCopyPerson(newPerson);
    }

  }, [person]);

  useEffect(() => {
  }, [copyPerson]);


  const getLastConfirm = () => {
    let menuNotSave = {};

    menu.forEach((item, idx) => {

      if (menuConfirm && menuConfirm.find(i => i == item.idHreStep)) {
        if ((idx + 1) <= menu.length && !menuConfirm.find(i => menu[idx + 1] && i == menu[idx + 1].idHreStep)) {
          const _menuNotSave = menu[idx + 1]
          menuNotSave = _menuNotSave;
        }
      }
    });

    return menuNotSave;
  }
  const getMenus = () => {

    const menuNotSave = getLastConfirm();

    let lastMenu = {};

    return menu.length ? menu.map((item) => {

      if(item.id === 0)
          item.stepName = i18n.t("summary");

      if (menuConfirm && menuConfirm.find(i => i == item.idHreStep)) {
        lastMenu = item;
        return <li className={activeMenu == item.id ? 'ck-save' : 'ck'} onClick={() => next(item)} style={{ backgroundImage: `url('data:image/svg+xml,${checkedLi}')`, color: activeMenu == item.id ? parameter.backgroundColor : '#152c5b' }}><div className='ul-li-menu'>{item.stepName}</div></li>
      } else {
        if ((menuNotSave && menuNotSave.idHreStep == item.idHreStep) || menuConfirm.find(i => i == lastMenu.idHreStep)) {
          lastMenu = item;
          return <li className={activeMenu == item.id ? 'ck-focus' : ''} onClick={() => next(item)} style={{ backgroundImage: `url('data:image/svg+xml,${li}')`, color: '#152c5b', cursor: 'pointer' }}><div className='ul-li-menu'>{item.stepName}</div></li>
        } else {
          lastMenu = item;
          return <li className={activeMenu == item.id ? 'ck-focus' : ''} style={{ backgroundImage: `url('data:image/svg+xml,${li}')`, color: activeMenu == item.id ? parameter.backgroundColor : '#718096' }}><div className='ul-li-menu'>{item.stepName}</div></li>
        }
      }


    }) : '';
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className={person.hasNavigationMenu ? "container-menu" : "container"}>
          {person.hasNavigationMenu ? '' : <img src={!parameter?.urlLogoImage ? '' : parameter.urlLogoImage} alt="Onboarding" width="200" />}
        </div>
      </nav>
      <div className={person.hasNavigationMenu ? "container-menu" : "container"}>
        {person.hasNavigationMenu ?
          <div className='row'>
            <div className="ul-menu col-md-auto row-box-shodow mr-2 mr-lg-3">
              <img src={!parameter?.urlLogoImage ? '' : parameter.urlLogoImage} alt="Onboarding" width="150" className='app-logo' />
              <ul className='mt-3'>
                {getMenus()}
              </ul>
            </div>
            <div className='col-9 col-md-9 col-lg-9' style={{ marginTop: '80px' }}>{props.children}</div>
          </div> : props.children}
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired
};

export default App;
