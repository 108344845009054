import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'Person';

service.fileConfig = () => {
  return apiUtils.exec(serviceName + '/FileConfig');
};

service.sendCode = code => {
  return apiUtils.exec(serviceName + `/ValidateTokenSms/${code}`);
};

service.checkSecondAuthenticationRequirement = () =>{
  return apiUtils.exec(serviceName + '/CheckSecondAuthenticationRequirement');
};

service.getCelphoneNumber = () =>{
  return apiUtils.exec(serviceName + '/GetCelphoneNumber');
};

service.sendTokenSms = () =>{
  return apiUtils.exec(serviceName + '/SendTokenSms');
};

service.files = (idCompany, idHreStepCompany, skip) => {
  return apiUtils.exec(serviceName + `/Files/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}/skip/${skip}`);
};

service.filesSettings = (idCompany, idHreStepCompany, skip) => {
  return apiUtils.exec(serviceName + `/FileSettings/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}/skip/${skip}`);
};


service.filesCustom = (idCompany, idHreStepCompany, idCustom) => {
  return apiUtils.exec(serviceName + `/Files/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}/idCustom/${idCustom}`);
};

service.countFiles = (idCompany, idHreStepCompany) => {
  return apiUtils.exec(serviceName + `/CountFiles/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}`);
};

service.updateFiles = (person) => {
  return apiUtils.exec(serviceName + '/UpdateFiles', apiUtils.getFetchParameters('PUT', person));
};

service.put = person => {
  return apiUtils.exec(serviceName, apiUtils.getFetchParameters('PUT', person));
};

service.getCountry = () => {
  return apiUtils.exec(serviceName + '/Country');
};

service.getTypeDistrict = () => {
  return apiUtils.exec(serviceName + '/TypeDistrict');
};

service.getClassOrgan = () => {
  return apiUtils.exec(serviceName + '/ClassOrgan');
};


service.getState = id => {
  return apiUtils.exec(serviceName + `/State/${id}`);
};

service.getPerson = () => {
  return apiUtils.exec(serviceName + '/Person');
};

service.getPersonReview = () => {
  return apiUtils.exec(serviceName + '/PersonReview');
};

service.getCity = id => {
  return apiUtils.exec(serviceName + `/City/${id}`);
};

service.getStepCompanyReview = id => {
  return apiUtils.exec(serviceName + `/HreStepCompanyReview/${id}`);
};

service.getNtcParameter = () => {
  return apiUtils.exec(serviceName + '/NtcParameter');
};

service.getFieldConfig = (tag, idCompany) => {
  return apiUtils.exec(serviceName + `/FieldConfig/${tag}/IdCompany/${idCompany}`);
};

service.getMaritalStatus = () => {
  return apiUtils.exec(serviceName + '/MaritalStatus');
};

service.getParameters = (divisionId) => {
  return apiUtils.exec(serviceName + `/Parameters/${divisionId}`);
};

service.getPhone = (idCompany, idHreStepCompany) => {
  return apiUtils.exec(serviceName + `/HreStepCompanyFieldPhone/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}`);
};
service.getCompl = (idCompany, idHreStepCompany) => {
  return apiUtils.exec(serviceName + `/HreCompl/IdCompany/${idCompany}/IdHreStepCompany/${idHreStepCompany}`);
};
service.getHreStep = (divisionId, order) => {
  return apiUtils.exec(serviceName + `/idCompany/${divisionId}/stepOrder/${order}`);
};
service.getConfigMarital = (divisionId, idHreStep, idHreStepCompany) => {
  return apiUtils.exec(serviceName + `/MaritalStatus/idCompany/${divisionId}/idHreStep/${idHreStep}/idHreStepCompany/${idHreStepCompany}`);
};

service.getHreOrder = (divisionId, order) => {
  return apiUtils.exec(serviceName + `/idCompany/${divisionId}/orderNext/${order}`);
};
service.getHreOrderLast = (divisionId, order) => {
  return apiUtils.exec(serviceName + `/idCompany/${divisionId}/orderLast/${order}`);
};

service.PutInfo = person => {
  return apiUtils.exec(serviceName + '/UpdateInfo', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateMaritalStatus = person => {
  return apiUtils.exec(serviceName + '/UpdateMaritalStatus', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateAddress = person => {
  return apiUtils.exec(serviceName + '/UpdateAddress', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateFood = person => {
  return apiUtils.exec(serviceName + '/UpdateFood', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateIdentityAndVoter = person => {
  return apiUtils.exec(serviceName + '/UpdateIdentityAndVoter', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateIdentityAndPis = person => {
  return apiUtils.exec(serviceName + '/UpdateIdentityAndPis', apiUtils.getFetchParameters('PUT', person));
};

service.UpdatePCD = person => {
  return apiUtils.exec(serviceName + '/UpdatePCD', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateClassOrgan = person => {
  return apiUtils.exec(serviceName + '/UpdateClassOrgan', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateWorkAndDriver = person => {
  return apiUtils.exec(serviceName + '/UpdateWorkAndDriver', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateMilitary = person => {
  return apiUtils.exec(serviceName + '/UpdateMilitary', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateAcceptProceed = person => {
  return apiUtils.exec(serviceName + '/UpdateAcceptProceed', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateDoc = person => {
  return apiUtils.exec(serviceName + '/UpdateMilitary', apiUtils.getFetchParameters('PUT', person));
};

service.UpdateDoc = dependent => {
  return apiUtils.exec(serviceName + '/UpdateDependents', apiUtils.getFetchParameters('PUT', dependent));
};

service.PostDependents = dependent => {
  return apiUtils.exec(serviceName + '/Dependents', apiUtils.getFetchParameters('POST', dependent));
};

service.GetDependents = idHreStepCompany => {
  return apiUtils.exec(serviceName + `/Dependents/IdHreStepCompany/${idHreStepCompany}`);
};
service.GetEducations = () => {
  return apiUtils.exec(serviceName + '/Educations');
};

service.DeleteDependents = dependent => {
  return apiUtils.exec(serviceName + '/Dependents', apiUtils.getFetchParameters('DELETE', dependent));
};


service.getStateByCountry = () => {
  return apiUtils.exec(serviceName + '/StateByCountry');
};

service.getGetKinships = () => {
  return apiUtils.exec(serviceName + '/GetKinships');
};

service.sendToSMS = () => {
  return apiUtils.exec(serviceName + '/SendToSMS');
};
service.getTypeStreet = () => {
  return apiUtils.exec(serviceName + '/TypeStreet');
};
service.getEducationLevel = () => {
  return apiUtils.exec(serviceName + '/EducationLevel');
};
service.getBreedColor = () => {
  return apiUtils.exec(serviceName + '/BreedColor');
};

service.getLinkDownload = (divisionId) => {
  return apiUtils.exec(serviceName + `/LinkDownload/${divisionId}`);
};

service.getEducationLevelRm = () => {
  return apiUtils.exec(serviceName + '/EducationLevelRm');
};
service.getEntityRm = () => {
  return apiUtils.exec(serviceName + '/EntityRm');
};

service.getCourse = (divisionId) => {
  return apiUtils.exec(serviceName + `/Course/${divisionId}`);
};

service.postEducation = education => {
  return apiUtils.exec(serviceName + '/Educations', apiUtils.getFetchParameters('POST', education));
};

service.deleteEducation = education => {
  return apiUtils.exec(serviceName + '/DeleteEducation', apiUtils.getFetchParameters('POST', education));
};


service.GetCertificates = () => {
  return apiUtils.exec(serviceName + '/Certificates');
};

service.getCertificateById = id => {
  return apiUtils.exec(serviceName + `/Certificates/${id}`);
};

service.postCertificate = certificate => {
  const method = certificate.id ? 'PUT' : 'POST';
  return apiUtils.exec(serviceName + '/Certificates', apiUtils.getFetchParameters(method, certificate));
};

service.deleteCertificate = certificate => {
  return apiUtils.exec(serviceName + '/Certificates', apiUtils.getFetchParameters('DELETE', certificate));
};

service.getCertificateById = id => {
  return apiUtils.exec(serviceName + `/Certificates/${id}`);
};

service.getStepByType = (divisionId, stepType) => {
  return apiUtils.exec(serviceName + `/idCompany/${divisionId}/stepType/${stepType}`);
};

export default service;