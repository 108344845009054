import * as types from './actionTypes';
const actions = {};

const InitDivisionId = divisionId => {
  return { type: types.SET_DIVISION_ID, divisionId};
};

actions.init = divisionId => {
  return InitDivisionId(divisionId);
};

export default actions;
