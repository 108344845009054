import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from "react-i18next";

const AsyncComponent = props => {

  const [Component, setComponent] = useState(null);

  
  useEffect(() => {
    if (props.importComponent) {
      setComponent(lazy(props.importComponent));
    }
  }, [props.importComponent]);

  if (!Component) return (<div><Trans i18nKey="loading"></Trans></div>);
  return (<Component {...props}/>);
};

AsyncComponent.propTypes = {
  importComponent: PropTypes.func.isRequired,
  showCompetence: PropTypes.bool,
  showCompanies: PropTypes.bool,
  showFilial: PropTypes.bool
};

export default AsyncComponent;