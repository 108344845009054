import React, { useEffect, useState } from 'react';
import Routes from './routes';
import loginActions from './actions/loginActions';
import divisionActions from './actions/divisionActions';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from './components/ErrorPage';
import { PulseLoader } from 'react-spinners';
import { Trans } from "react-i18next";

const StartPage = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [errorImageUrl, setErrorImageUrl] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    const urlParts = window.location.href.split('/');
    if (urlParts.length < 4 || !urlParts[4] || urlParts[4] === '#') console.log('Não foi possível identificar o token', urlParts);

    const token = urlParts[4];

    loginActions.login(token).then(data => {
      if (data?.authenticated) {
        dispatch(loginActions.init(data));
        // divisionId precisa ser retornado no login e setado abaixo
        dispatch(divisionActions.init(data.divisionId));
      } else {
        setErrorImageUrl(data?.errorImageUrl);
        dispatch(loginActions.quit());
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  const getContent = () => {
    if (isLoading) {
      const component =
        <section className="slice py-7">
          <div className="container">
            <div className="row row-grid align-items-center">
              <h3><Trans i18nKey="loading"></Trans> </h3>
              <PulseLoader size={10} color={'#333'} loading={isLoading} />
              <span className="control-label"><Trans i18nKey="waitMessage"></Trans> </span>
            </div>
          </div>
        </section>;
      return component;
    }
    return user.isAuth ? <Routes /> : <ErrorPage errorImageUrl={errorImageUrl} />;
  };

  return (
    getContent()
  );
};

export default StartPage;
