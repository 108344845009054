import {combineReducers} from 'redux';
import user from './loginReducer';
import division from './divisionReducer';

const rootReducer = combineReducers({
  user,
  division
});

export default rootReducer;
