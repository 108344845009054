import { atom, selector } from 'recoil';

export const activeMenuSelector = selector({
  key: 'activeMenuSelector',
  get: () => {
    return -1
  }
});

export const activeMenuAtom = atom({
  key: 'activeMenuAtom',
  default: activeMenuSelector
});