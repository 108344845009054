import * as types from '../actions/actionTypes';
import initialState from './initialState';

const loginReducer = (state = initialState.user, action) => {
  switch (action.type) {
  case types.LOGIN_LOGOUT_SUCCESS:
    return Object.assign({}, state, {
      isAuth: action.user.isAuth,
      admin: action.user.isAdmin,
      endPoint: action.user.endPoint
    });

  case types.SET_ADMIN_MODE:
    return Object.assign({}, state, {
      administrationMode: action.admMode
    });

  default:
    return state;
  }
};

export default loginReducer;
