import * as types from '../actions/actionTypes';
import initialState from './initialState';

const loginReducer = (state = initialState.division, action) => {
  switch (action.type) {
  case types.SET_DIVISION_ID:
    return Object.assign({}, state, {
      divisionId: action.divisionId
    });

  default:
    return state;
  }
};

export default loginReducer;
