import * as types from './actionTypes';
import services from '../api/services/tokenServices';

const TOKEN = 'accessToken';
const INTERNAL_TOKEN = 'internalToken';
const actions = {};

const loginLogoutSuccess = isAuth => {
  const user = {
    isAuth
  };
  return { type: types.LOGIN_LOGOUT_SUCCESS, user};
};

actions.login = token => {
  return services.login(token);
};

actions.quit = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(INTERNAL_TOKEN);
  return loginLogoutSuccess(false);
};

actions.init = data => {
  localStorage.setItem(TOKEN, data.accessToken);
  localStorage.setItem(INTERNAL_TOKEN, data.internalToken);

  return loginLogoutSuccess(true);
};

export default actions;
