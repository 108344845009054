import React from 'react';
import { createRoot } from 'react-dom/client';
import Start from './StartPage';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { loadState, saveState } from './store/localStorage';
import throttle from 'lodash/throttle';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css?v=1.2.9';
import '../node_modules/toastr/build/toastr.min.css';
import './theme.css';
import { RecoilRoot } from 'recoil';

const persistedState = loadState();

const store = configureStore(persistedState);
store.subscribe(throttle(() => { saveState(store.getState()); }, 1000));

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <RecoilRoot>
      <Start />
    </RecoilRoot>
  </Provider>
)

export const defaultStore = store;

