import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'login';

service.login = token => {
  const removeAuth = true;
  return apiUtils.exec(`${serviceName}/token/`, apiUtils.getFetchParameters('POST', token, removeAuth));
};

export default service;
