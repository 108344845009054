import { atom, selector } from 'recoil';
import service from '../api/services/personServices';

export const personSelector = selector({
  key: 'personSelector',
  get: () => {
    return service.getPerson().then(res => res.data);
  }
});

export const personAtom = atom({
  key: 'personAtom',
  default: personSelector
});