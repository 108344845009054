import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'ClientParameter';

service.get = () => {
  return apiUtils.exec(serviceName + '/GetClientParameter');
};


export default service;