const initialState = {
  user: {
    isAuth: false
  },
  division: { 
    divisionId: ''
  }
};

export default initialState;
