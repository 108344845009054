import { PulseLoader } from "react-spinners";

const Loading = () => {
  return <section className="slice py-7">
    <div className="container">
      <PulseLoader size={10} color={'#333'} loading={true} />
      <br />
      <label className="control-label">Favor aguardar, estamos processando suas informações.</label>
    </div>
  </section>;
};

export default Loading;