import loginActions from '../actions/loginActions';

const apiUtils = {};

const GetConfig = async () => {
  const res = await fetch('../../appsettings.json');
  let data;
  try {
    data = await res.json();
  } catch (e) {
    console.log('error:', e);
  }
  return data;
};

apiUtils.checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

apiUtils.getFetchParameters = (method, body, removeAuth) => {
  const param = {
    method,
    headers: { 'Content-Type': 'application/json' }
  };

  if (!removeAuth) {
    param.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
    param.headers.InternalToken = localStorage.getItem('internalToken');
  }
  if (body) {
    param.body = JSON.stringify(body);
  }

  return param;
};

apiUtils.exec = async (endpoint, params) => {
  var internalParams = params || apiUtils.getFetchParameters('GET');
  const config = await GetConfig();
  const url = config.apiUrl + endpoint;

  return await fetch(url, internalParams)
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      } else if (res.status === 401) {
        // eslint-disable-next-line no-console
        console.log('API returned 401');
        loginActions.quit();
        // Force iterruption of promise
        return Promise.reject();
      } else if (res.status === 403) {
        return apiUtils.Unauthorized();
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Error: ', err);
      loginActions.quit();
      return Promise.reject(err);
    });
};

apiUtils.execAsync = async (endpoint, params) => {
  var internalParams = params || apiUtils.getFetchParameters('GET');
  const config = await GetConfig();
  const url = config.apiUrl + endpoint;

  const res = await fetch(url, internalParams);

  if (res && res.status === 403) {
    return apiUtils.Unauthorized();
  }

  return res.json();
};

export default apiUtils;
