import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'Parameter';

service.getStepsByCompany = idCompany =>{
  return apiUtils.exec(`${serviceName}/HreStep/${idCompany}`);
};

service.stepCompanyList = idCompany =>{
  return apiUtils.exec(`Step/stepCompanyList/${idCompany}`);
};

service.stepSavedList = idCompany =>{
  return apiUtils.exec('Step/stepSavedList');
};

export default service;